<template>
  <div class="input-erro-container">
    <slot></slot>
    <transition name="errofade">
      <span :class="color === 'white' ? 'input-erro-mensagem white' : 'input-erro-mensagem'" v-if="erro != ''">{{ erro }}</span>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["erro", "color"],
};
</script>

<style lang="scss" scoped>
.errofade-enter-active {
  animation: fadeEnter 0.3s;
}
.errofade-leave-active {
  animation: fadeOut 0.3s;
}
.input-erro-container {
  position: relative;
}
.input-erro-mensagem {
  font-size: 12px;
  position: absolute;
  bottom: 5px;
  left: 5px;
  color: $erro;
  &.white {
    color: white !important;
  }
}
.singnup {
  .input-erro-mensagem {
    bottom: 10px;
  }
}
</style>
